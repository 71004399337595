import { inject, Injectable, signal } from '@angular/core';
import { Geolocation, Position, PositionOptions } from '@capacitor/geolocation';
import { AppDataService } from '@service/app-data.service';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  /* eslint-disable */
  private appDataService = inject(AppDataService);
  private watchId: string;

  appSettings = this.appDataService.appSettings;
  isDeviceOnAlpeDhuez = signal<boolean>(false);
  isLocationEnabled = signal<boolean>(true);
  isWatching = signal<boolean>(false);
  position = signal<Position>(null);
  /* eslint-enable */

  initialize(): Promise<void> {
    // this function is intentionally left blank
    console.log('Initialized PositionService');

    return Promise.resolve();
  }

  // TODO: add unit test
  isPositionOnAlpeDhuez(position: Position): boolean {
    let isOnAlpeDhuez = false;

    if (position && position.coords && this.appSettings().mapSquare) {
      isOnAlpeDhuez = (
        position.coords.latitude < this.appSettings().mapSquare.northEast.latitude &&
        position.coords.latitude > this.appSettings().mapSquare.southWest.latitude &&
        position.coords.longitude > this.appSettings().mapSquare.northEast.longitude &&
        position.coords.longitude < this.appSettings().mapSquare.southWest.longitude
      );
    }

    return isOnAlpeDhuez;
  }

  startWatchingPosition(): void {
    // Create a new watch for position only once
    if (this.watchId) {
      console.warn('Position already watched through id', this.watchId);
    } else {
      this.isWatching.set(true);
      // TODO Removed the setting of the watchId when upgrading frameworks. Since it is Promise, it would normally only resolve once. Needs to be retested.
      // this.watchId =
      Geolocation.watchPosition(this.createGeolocationOptions(), (watchedPosition, error) => {
        if (error === undefined) {
          this.processWatchedPosition(watchedPosition);
        }
      });
    }
  }

  stopWatchingPosition(): void {
    if (this.watchId) {
      Geolocation.clearWatch({ id: this.watchId });

      // TODO: check if there is a beter way than delete
      delete this.watchId;
    }
    this.position.set(null);
    this.isWatching.set(false);
  }

  private createGeolocationOptions(): PositionOptions {
    // TODO!
    // Look at https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-geolocation/#methods for geolocationOptions
    return {};
  }

  private processWatchedPosition(position: Position): void {
    if (position) {
      const isPositionOnAlpeDhuez = this.isPositionOnAlpeDhuez(position);
      if (this.isDeviceOnAlpeDhuez() !== isPositionOnAlpeDhuez) {
        // this.isDeviceOnAlpeDhuez.next(isPositionOnAlpeDhuez);
      }
      if (isPositionOnAlpeDhuez) {
        // TODO: send position to backend?
        this.position.set(position);
      }
    }
  }

}
